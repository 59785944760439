
export default {

  props: {
    value: { type: String, default: '' },
    error: { type: String, default: '' }
    placeholder: { type: String, default: '' }
  }

  data: -> {
    reveal_password: false,
    password: '',
    rules: {
      required: (value) =>
        !!value || @$t('user_form_validate_required')

      length: (value) =>
        value.length >= 6 || @$t('input_password_validate_length')
    }
  }

  mounted: ->
    @password = @value

  watch: {
    password: ->
      @$emit('input', @password)
  }

  computed: {
    placeholder_text: ->
      @placeholder.length > 1 && @placeholder || @$t("input_password_placeholder")
  }

}
