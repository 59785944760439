
import tool from '../lib/helpers'
import AddressPicker from '@/components/address_picker'
import InputTelephone from '@/components/input_telephone'
import PageNavbar from '@/components/page_navbar'
import PasswordField from '@/components/form/password_field'
import { as_json } from '@/lib/helpers'
import { user_password } from '@/lib/validators'

export default {
  components: { AddressPicker, InputTelephone, PasswordField, PageNavbar }

  data: ->
    {
      tab: 1,
      user: {
        login: "",
        password: "",
        first_name: "",
        last_name: "",
        profile: {
          terms: false
          sms_marketing: true
          address: { latitude: null, longitude: null, formatted: null }
        }
      }
      confirm_password: "",
      show_password: false,
      show_confirm_password: false,
      rules: {
        required: (value) => !!value || @$t('user_form_validate_required')
        password: (value) =>
          if (typeof user_password(value)) == 'boolean' then true else @$t("user_form_validate_password_#{user_password(value)}")
      }
    }

  computed: {
    agency_application: ->
      @$store.getters['application/is_agency']

    compare_passwords: ->
      if @user.password != @confirm_password then @$t('form_validate_password_match') else true

    logged_in: ->
      @$store.getters['account/logged_in']

    api_validation: ->
      @$store.state.account.errors || {}

    loading: ->
      @$store.state.account.loading

    tabs: ->
      [1, 2]
  },

  created: ->

  destroyed: ->
    @$store.commit('account/flush_errors')

  methods: {
    next_tab: ->
      current = @tabs.indexOf(@tab)
      if @tab == 1
        @tab = @tab + 1 if @$refs.user_fields.validate()
      else
        @tab = @tab + 1
    ,
    previous_tab: ->
      @tab = @tab - 1 || 0
    ,
    submit: ->
      if @$refs.user_fields.validate() && @$refs.user_name.validate()
        @$store.dispatch('account/sign_up', tool.as_json(@user))
        .then => @$store.dispatch('layout/notify', @$t('popup_account_sign_up_ok'))
    ,
    before_tab_change: (event) ->
      if @tabs.indexOf(@tab) == 0
        event.stopPropagation() if not @$refs.user_fields.validate()
    ,
    request_address: ->
      @$store.commit('address_picker/open', as_json(@user.profile.address))
    ,
    set_address: (address) ->
      @user.profile.address = address
      @$refs.address.blur()
  },

  watch: {
    logged_in: (logged_in) ->
      if logged_in
        destination = @$route.query?.redirect_to || '/'
        @$router.push(destination)
    ,
    api_validation: (error_object) ->
      @tab = 1 if error_object
  }
}
